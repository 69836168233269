<template>
  <div>
    <banner :title="title" :subtitle="subtitle"></banner>
    <div class="card-wrap">
      <div class="card-content">
        <production-card
          v-for="(item, idx) in cards"
          :key="idx"
          :title="item.Name"
          :desc="item.ProductDescription"
          :imgSrc="item.ImagePath"
          :id="item.Id"
        ></production-card>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/NormalBanner.vue";
import ProductionCard from "../components/production/ProductionCard.vue";
import { get } from "../plugins/axios";
export default {
  components: { Banner, ProductionCard },
  data() {
    return {
      title: "",
      subtitle: "",
      cards: [
        // {
        //   title: "政府行业软件",
        //   desc:
        //     "具体文案待定，这里可以写三行。具体文案待定，这里可以写三行。具体文案待定。",
        //   imgSrc: require("@/assets/logo.png"),
        //   id: "prod1",
        // },
        // {
        //   title: "政府行业软件",
        //   desc:
        //     "具体文案待定，这里可以写三行。具体文案待定，这里可以写三行。具体文案待定。",
        //   imgSrc: require("@/assets/demo.png"),
        //   id: "prod2",
        // },
        // {
        //   title: "政府行业软件",
        //   desc:
        //     "具体文案待定，这里可以写三行。具体文案待定，这里可以写三行。具体文案待定。",
        //   imgSrc: "",
        //   id: "prod3",
        // },
        // {
        //   title: "政府行业软件",
        //   desc:
        //     "具体文案待定，这里可以写三行。具体文案待定，这里可以写三行。具体文案待定。",
        //   imgSrc: "",
        //   id: "prod4",
        // },
      ],
    };
  },
  mounted() {
    this.$nextTick(function () {
      get("/HomeApi/QueryProduct")
        .then((data) => {
          this.cards = data;
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
        });

      get(`/HomeApi/QueryItemDetailByEncode`, { encode: "CPZX" })
        .then((data) => {
          this.title = data.Name;
          this.subtitle = data.Attr3;
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
};
</script>

<style lang="less" scoped>
.card-wrap {
  max-width: 1440px;
  min-width: 1260px;
  margin: 0 auto;
}
.card-content {
  margin: 30px 20px;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 50%));
  grid-gap: 20px;
  justify-items: center;
  justify-content: center;
}

.prod-card {
  width: 100%;
  height: 400px;
}
</style>
